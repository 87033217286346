import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: "",
      fNameValue: "",
      lNameValue: ""
    };
  }

  render() {
    return (
      <form
        action="https://zenabshoney.us20.list-manage.com/subscribe/post?u=a5701c64f9d5bc417f1dc2037&amp;id=548444b578"
        method="POST"
        noValidate
        className="form-group"
      >
        <h2 className="script">Mailing List</h2>
        <input type="hidden" name="u" value="a5701c64f9d5bc417f1dc2037" />
        <input type="hidden" name="id" value="548444b578" />
        <div className="columns is-12">
          <div className="column is-6">
            <div className="field">
              <label htmlFor="MERGE1" className="label">
                First name<sup>*</sup>
              </label>
              <div className="control">
                <input
                  type="text"
                  name="FNAME"
                  id="MERGE1"
                  className="input"
                  placeholder="First Name"
                  value={this.state.fNameValue}
                  onChange={e => {
                    this.setState({ fNameValue: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="column is-6">
            <div className="field">
              <label htmlFor="MERGE2" className="label">
                Last name<sup>*</sup>
              </label>
              <div className="control">
                <input
                  type="text"
                  name="LNAME"
                  id="MERGE2"
                  className="input"
                  placeholder="Last Name"
                  value={this.state.lNameValue}
                  onChange={e => {
                    this.setState({ lNameValue: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label htmlFor="MERGE0" className="label">
            Email<sup>*</sup>
          </label>
          <div className="control has-icons-left has-icons-right">
            <input
              type="email"
              name="EMAIL"
              id="MERGE0"
              className="input"
              placeholder="Email"
              value={this.state.emailValue}
              onChange={e => {
                this.setState({ emailValue: e.target.value });
              }}
              autoCapitalize="off"
              autoCorrect="off"
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
          </div>
        </div>

        <button
          value="Subscribe"
          name="subscribe"
          className="button yellow"
          type="submit"
          id="mc-embedded-subscribe">Subscribe
        </button>

        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
          aria-label="Please leave the following three fields empty"
        >
          <label htmlFor="b_name">Name: </label>
          <input
            type="text"
            name="b_name"
            tabIndex="-1"
            value=""
            placeholder="Freddie"
            id="b_name"
          />

          <label htmlFor="b_email">Email: </label>
          <input
            type="email"
            name="b_email"
            tabIndex="-1"
            value=""
            placeholder="youremail@gmail.com"
            id="b_email"
          />

          <label htmlFor="b_comment">Comment: </label>
          <textarea
            name="b_comment"
            tabIndex="-1"
            placeholder="Please comment"
            id="b_comment"
          />
        </div>
      </form>
    );
  }
}

export default SignupForm;
